<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <!-- button return -->
          <v-btn
            @click="$router.go(-1)"
            text
            class="pl-0"
          >
            <v-icon
              class="icon-return"
              size="15px"
            >
              mdi-arrow-left
            </v-icon>
            <span class="text-return mon-regular">{{ texts.viewArticle.textButtonReturn }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "ViewArticleSearchbarLayout",
};
</script>

<style scoped>
.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}
</style>